<template>
    <div>
        <top-nav title="Nueva sesión"></top-nav>
        <v-card flat style="width:100%;">
            <div v-show="!hideForm">
                <v-card-title>Crear nueva sesión</v-card-title>
                <v-card-subtitle>Ponle un nombre y presiona el botón para crear un QR Code que las <i>Petu Girls</i> podrán usar para hacer check-in.</v-card-subtitle>
            </div>

            <v-card-text v-show="!hideForm">
                <v-form ref="form">
                    <v-container fluid fill-height>
                        <v-text-field v-model="name" label="Nombre de la sesión" outlined :rules="nameRules"></v-text-field>
                        <v-select v-model="type" :items="types" label="Tipo de sesión" :rules="typeRules" outlined></v-select>
                        <v-btn depressed block color="primary" @click="openSession" :loading="loading">Crear</v-btn>
                    </v-container>
                </v-form>
            </v-card-text>

            <div v-show="hideForm">
                <v-card-title>Sesión: {{ name }}</v-card-title>
                <v-card-subtitle>Escanee el código QR para hacer check-in a esta sesión</v-card-subtitle>
            </div>
            <v-card-text v-show="hideForm">
                <qr-code :text="checkInUrl" style="width:100vw;margin:0 -28px;text-align:center;"></qr-code>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { _st } from '@/softech';
import TopNav from '@/components/admin/TopNav';
import QrCode from '@/components/QrCode';
import { PetuSession } from '@/classes/session'
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    data() {
        return {
            name        : moment().format('MMM DD, YYYY @ hh:mm a'),
            nameRules   : [
                v => !!v || "requerido"
            ],
            type        : '',
            types       : [
                { text: 'Presencial', value: 'presencial' },
                { text: 'One on one', value: 'one on one' }
            ],
            typeRules   : [
                v => !!v || 'requerido'
            ],

            loading     : false,
            hideForm    : false,
            checkInUrl  : ''
        }
    },
    computed: {
        ...mapGetters({
            user        : 'auth/user',
            customer    : 'auth/customer',
        })
    },
    methods: {
        async openSession() {
            try {
                if( !this.$refs.form.validate() )
                    return;

                this.loading = true;
                
                let session = new PetuSession();
                session.data.name = this.name;
                session.data.customerId = this.customer.id;
                session.data.type = this.type;
                await session.save();

                let base = process.env.NODE_ENV === "development" ? "http://localhost:8080/checkin/" : "https://petupower.fitness/checkin/";
                this.checkInUrl = `${base}${btoa(session.data.id)}`;

                this.loading = false;
                this.hideForm = true;
            }
            catch(error) {
                console.log(error);
                this.loading = false;
            }
        },
    },
    components: {
        TopNav,
        QrCode
    }
}
</script>

<style lang="scss" scoped>

</style>